import React from 'react'
import {
  Container,
  DownloadButton,
  LinkButton,
  Section,
  Seo,
} from '@components'
import { OfferContent } from '../../../components/site/offer'
import { PageLayout } from '../../../components/site/layout/page-layout'

import { PdfViewer } from "../../../components/core/pdf";

const offer = 'mhealth_report'
const pdfUrl = '/download/all-that-we-let-in-hacking-mhealth-apps-with-apis.pdf'
const pdfName = 'All That We Let In - Hacking mHealth Apps With APIs.pdf'

const ViewPage = ({ location }) => {
  const Headline = () => {
    return (
      <Section>
        <Container className="mb-8 container-lg">
          <h1 className="mt-12 mb-4 text-3xl text-center">
            All That We Let In - Hacking mHealth Apps With APIs
          </h1>
        </Container>
      </Section>
    )
  }

  const Action = () => {
    return (
      <>
        <p>
          Approov can help your business secure its mobile APIs quickly and
          effectively. Want to learn more about how we helped dozens of
          companies ensure that only their mobile apps can use their APIs? Talk
          to us.
        </p>

        <div className="flex flex-col items-center">
          <LinkButton
            className="button-secondary button-lg"
            href="/product/consult"
          >
            Talk to an Expert
          </LinkButton>
        </div>
      </>
    )
  }

  return (
    <PageLayout pathname={location.pathname}>

      <Headline />

      <OfferContent offer={offer}>
        <Section>
          <div className="flex items-center justify-center py-4">
            <DownloadButton
              className="button-secondary button-lg"
              downloadUrl={pdfUrl}
              fileName={pdfName}
            >
              Download PDF
            </DownloadButton>
          </div>
          <PdfViewer file={pdfUrl} />

          <Container className="mb-8">
            <Action />
          </Container>
        </Section>
      </OfferContent>
    </PageLayout>
  )
}

export default ViewPage
